import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatepicker from "components/CustomDatepicker/CustomDatepicker.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

// API - Client
import { client } from "Client";

const style = {
  loading: {
    color: "#ff9800",
  },
  btnContainer: {
    textAlign: "center",
    marginTop: "15px",
  },
};

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

const useStyles = makeStyles(style);

const defaultAreas = [
  {
    id: 1,
    name: "Ingeniería",
  },
  {
    id: 2,
    name: "Ciencias Sociales",
  },
  {
    id: 3,
    name: "Ciencias Jurídicas y políticas",
  },
  {
    id: 4,
    name: "Ciencias Administrativas",
  },
  {
    id: 5,
    name: "Artes",
  },
  {
    id: 6,
    name: "Ciencias de la Salud",
  },
  {
    id: 7,
    name: "Ciencias Básicas",
  },
];

const defaultSocialMedia = [
  {
    id: 1,
    name: "Instagram",
  },
  {
    id: 2,
    name: "YouTube",
  },
  {
    id: 3,
    name: "Facebook",
  },
  {
    id: 4,
    name: "Twitter",
  },
  {
    id: 5,
    name: "TikTok",
  },
];

const defaultNextSteps = [
  {
    id: 1,
    name: "Programa profesional",
  },
  {
    id: 2,
    name: "Programa Técnico",
  },
  {
    id: 3,
    name: "Programa de Tecnología",
  },
  {
    id: 4,
    name: "Cursos cortos",
  },
];

export default function Step3(props) {
  const classes = useStyles();

  const { values, errors, onChange, onSubmit, loading } = props;

  // Student
  const {
    id,
    name,
    lastName,
    email,
    email2,
    phone,
    birthdate,
    parentName,
    parentPhone,
    preferredAreaId,
    preferredSocialM,
    wantNext,
    nextStep,
  } = values;

  // Fill out select items
  const [areas, setAreas] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [nextSteps, setNextSteps] = useState([]);

  useEffect(() => {
    source = CancelToken.source();
    axiosCancelToken = source.token;

    const requests = [
      client.getVGFAreas(axiosCancelToken),
      client.getVGFSocialMedia(axiosCancelToken),
      client.getVGFNextSteps(axiosCancelToken),
    ];

    Promise.all(requests)
      .then(([areasRes, socialMediaRes, nextStepsRes]) => {
        setAreas(areasRes);
        setSocialMedia(socialMediaRes);
        setNextSteps(nextStepsRes);
      })
      .catch(() => {
        setAreas(defaultAreas);
        setSocialMedia(defaultSocialMedia);
        setNextSteps(defaultNextSteps);
      });

    return () => source.cancel();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    onChange(name, value);
  };

  const handleButton = () => {
    onSubmit();
  };

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText="N° de Documento"
            id="id"
            value={Number(id) || null}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "id",
              type: "number",
            }}
            error={errors.id}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText="Nombre"
            id="name"
            value={name}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "name",
            }}
            error={errors.name}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText="Apellido"
            id="lastName"
            value={lastName}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "lastName",
            }}
            error={errors.lastName}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText="Correo electrónico"
            id="email"
            value={email}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "email",
            }}
            error={errors.email}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText="Confirma tu correo"
            id="email2"
            value={email2}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "email2",
            }}
            error={errors.email2}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText="Celular"
            id="phone"
            value={phone}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "phone",
            }}
            error={errors.phone}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomDatepicker
            labelText="Fecha de Nacimiento"
            id="birthdate"
            value={birthdate}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "birthdate",
            }}
            error={errors.birthdate}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText="Nombre del padre de familia"
            id="parentName"
            value={parentName}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "parentName",
            }}
            error={errors.parentName}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText="Número de contacto de padre de familia"
            id="parentPhone"
            value={parentPhone}
            onChange={handleChange}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "parentPhone",
            }}
            error={errors.parentPhone}
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <CustomSelect
            labelText="¿Por cuál canal prefieres seguir a tus influencers favoritos?"
            id="preferredSocialM"
            formControlProps={{
              fullWidth: true,
            }}
            selectProps={{
              name: "preferredSocialM",
            }}
            menuItems={socialMedia}
            value={preferredSocialM}
            onChange={handleChange}
            error={errors.preferredSocialM}
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <CustomSelect
            labelText="Al terminar el bachillerato, ¿Planeas seguir estudiando?"
            id="wantNext"
            formControlProps={{
              fullWidth: true,
            }}
            selectProps={{
              name: "wantNext",
            }}
            menuItems={["Si", "No"]}
            value={wantNext}
            onChange={handleChange}
            error={errors.wantNext}
          />
        </GridItem>
        {wantNext === "Si" && (
          <GridItem xs={12} sm={12}>
            <CustomSelect
              labelText="Genial! ¿Qué tienes planeado estudiar?"
              id="nextStep"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                name: "nextStep",
              }}
              menuItems={nextSteps}
              value={nextStep}
              onChange={handleChange}
              error={errors.nextStep}
            />
          </GridItem>
        )}
        <GridItem xs={12} sm={12}>
          <CustomSelect
            labelText="¿Cuál es tu área preferida?"
            id="preferredAreaId"
            formControlProps={{
              fullWidth: true,
            }}
            selectProps={{
              name: "preferredAreaId",
            }}
            menuItems={areas}
            value={preferredAreaId}
            onChange={handleChange}
            error={errors.preferredAreaId}
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8} className={classes.btnContainer}>
          {loading ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <Button
              variant="contained"
              className={classes.button}
              color="usb"
              size="lg"
              fullWidth
              onClick={handleButton}
            >
              ¡Obtener mis resultados!
            </Button>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
