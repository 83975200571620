import axios from "axios";

import routes from "routes/dashboard.js";

import io from "socket.io-client";

import qs from "qs";

const socketUri =
  process.env.NODE_ENV === "development"
    ? "localhost:3002"
    : "https://labmetrics.usbcali.edu.co";

class Client {
  constructor() {
    this.token = null;
    this.permissions = [];
    this.socket = null;
  }

  socketConnect() {
    const namespace = this.token ? "/admin" : "/";
    return io(socketUri + namespace, {
      transports: ["websocket"],
      path: "/apiv2",
      auth: {
        token: this.token,
      },
    });
  }

  socketInstance() {
    return this.socket || (this.socket = this.socketConnect());
  }

  isLoggedIn() {
    return !!this.token;
  }

  getRoutes() {
    const available = routes.map((route) => {
      if (route.collapse) {
        return {
          ...route,
          views: route.views.filter(
            (view) =>
              view.grantTo === undefined ||
              this.permissions.includes(view.grantTo)
          ),
        };
      } else if (
        route.grantTo === undefined ||
        this.permissions.includes(route.grantTo)
      ) {
        return route;
      } else {
        return null;
      }
    });

    return available.filter((route) => {
      if (route === null) {
        return false;
      } else if (route.collapse) {
        return !!route.views.length;
      } else {
        return true;
      }
    });
  }

  // isTokenValid = () => {
  //   return axios.post('/api/admin/checkToken/'+this.token)
  //   .then(({ data }) => data.valid)
  // }

  getToken = ({ username, password }) => {
    return axios
      .post("/api/admin/login", {
        username,
        password,
      })
      .then(async ({ data }) => {
        this.token = await data.api_token;
        this.permissions = await data.permissions;
      });
  };

  logout() {
    this.token = null;
  }

  getInstitutions = (cancelToken) => {
    return axios
      .get("/api/admin/institutions", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getPlayers = (cancelToken, rowsPerPage, page, search) => {
    return axios
      .get(
        `/api/admin/players?rowsPerPage=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: { Authorization: this.token },
          cancelToken,
        }
      )
      .then(({ data }) => data);
  };

  getUsers = (cancelToken, rowsPerPage, page) => {
    return axios
      .get(`/api/admin/users?rowsPerPage=${rowsPerPage}&page=${page}`, {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getCities = (cancelToken) => {
    return axios
      .get("/api/admin/cities", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getInstitutionTypes = (cancelToken) => {
    return axios
      .get("/api/admin/institution-types", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getScholarships = (cancelToken) => {
    return axios
      .get("/api/admin/scholarships", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getGenres = (cancelToken) => {
    return axios
      .get("/api/admin/genres", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getCommunications = (cancelToken) => {
    return axios
      .get("/api/admin/communications", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getAuditoryLossMoments = (cancelToken) => {
    return axios
      .get("/api/admin/auditory-loss-moments", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getAuditoryLossGrades = (cancelToken) => {
    return axios
      .get("/api/admin/auditory-loss-grades", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getDiagnoses = (cancelToken) => {
    return axios
      .get("/api/admin/diagnoses", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getRoles = (cancelToken) => {
    return axios
      .get("/api/admin/roles", {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  AddInstitution = ({ name, responsible, phone, email, city, type }) => {
    return axios.post(
      "/api/admin/institutions",
      {
        name,
        responsible,
        phone,
        email,
        city_id: city,
        institution_type_id: type,
      },
      {
        headers: { Authorization: this.token },
      }
    );
  };

  AddPlayer = ({
    username,
    password,
    name,
    lastName,
    birthdate,
    scholarship,
    genre,
    communication,
    institution,
    auditoryLossMoment,
    auditoryLossGrade,
    diagnoses,
  }) => {
    return axios.post(
      "/api/admin/players",
      {
        username,
        password,
        name,
        last_name: lastName,
        birthdate: birthdate !== "" ? birthdate : "2019-01-01",
        scholarship_id: scholarship,
        genre_id: genre,
        communication_id: communication,
        institution_id: institution,
        auditory_loss_moment_id:
          auditoryLossMoment !== "" ? auditoryLossMoment : null,
        auditory_loss_grade_id:
          auditoryLossGrade !== "" ? auditoryLossGrade : null,
        diagnoses,
      },
      {
        headers: { Authorization: this.token },
      }
    );
  };

  AddUser = ({ username, password, name, institution, role }) => {
    return axios.post(
      "/api/admin/users",
      {
        username,
        password,
        name,
        institutions: [institution],
        roles: [role],
      },
      {
        headers: { Authorization: this.token },
      }
    );
  };

  removeInstitution = (id) => {
    return axios
      .delete("/api/admin/institutions/" + id, {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  removePlayer = (id) => {
    return axios
      .delete("/api/admin/players/" + id, {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  removeUser = (id) => {
    return axios
      .delete("/api/admin/users/" + id, {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  updateInstitution = ({ id, name, responsible, phone, email, city, type }) => {
    return axios
      .put(
        "/api/admin/institutions/" + id,
        {
          name,
          responsible,
          phone,
          email,
          city_id: city,
          institution_type_id: type,
        },
        {
          headers: { Authorization: this.token },
        }
      )
      .then(({ data }) => data);
  };

  updatePlayer = ({
    id,
    username,
    password,
    name,
    lastName,
    birthdate,
    scholarship,
    genre,
    communication,
    institution,
    auditoryLossMoment,
    auditoryLossGrade,
    diagnoses,
  }) => {
    return axios
      .put(
        "/api/admin/players/" + id,
        {
          username,
          password: password ? password : null,
          name,
          last_name: lastName,
          birthdate: birthdate !== "" ? birthdate : "2019-01-01",
          scholarship_id: scholarship,
          genre_id: genre,
          communication_id: communication,
          institution_id: institution,
          auditory_loss_moment_id:
            auditoryLossMoment !== "" ? auditoryLossMoment : null,
          auditory_loss_grade_id:
            auditoryLossGrade !== "" ? auditoryLossGrade : null,
          diagnoses,
        },
        {
          headers: { Authorization: this.token },
        }
      )
      .then(({ data }) => data);
  };

  updateUser = ({ id, username, password, name, institution, role }) => {
    return axios
      .put(
        "/api/admin/users/" + id,
        {
          username,
          password: password ? password : null,
          name,
          institutions: [institution],
          roles: [role],
        },
        {
          headers: { Authorization: this.token },
        }
      )
      .then(({ data }) => data);
  };

  getPlayerReportData = ({ cancelToken, username, group, communication }) => {
    return axios
      .get(
        `/api/admin/report-player?username=${username}&group=${group}&communication=${communication}`,
        {
          headers: { Authorization: this.token },
          cancelToken,
        }
      )
      .then(({ data }) => data);
  };

  getInstitutionReportData = ({
    cancelToken,
    institution,
    group,
    scholarship,
  }) => {
    return axios
      .get(
        `/api/admin/report-institution?institution_id=${institution}&group=${group}&scholarship_id=${scholarship}`,
        {
          headers: { Authorization: this.token },
          cancelToken,
        }
      )
      .then(({ data }) => data);
  };

  getBCSPlayerReportData = ({
    cancelToken,
    username,
    group,
    communication,
  }) => {
    return axios
      .get(
        `/api/admin/report-bcs-player?username=${username}&group=${group}&communication=${communication}`,
        {
          headers: { Authorization: this.token },
          cancelToken,
        }
      )
      .then(({ data }) => data);
  };

  getBCSInstitutionReportData = ({
    cancelToken,
    institution,
    group,
    scholarship,
  }) => {
    return axios
      .get(
        `/api/admin/report-bcs-institution?institution_id=${institution}&group=${group}&scholarship_id=${scholarship}`,
        {
          headers: { Authorization: this.token },
          cancelToken,
        }
      )
      .then(({ data }) => data);
  };

  /**
   *  VOCATIONAL GUIDANCE FORM
   */

  getVGFAreaStatements = (cancelToken) => {
    return axios
      .get("/api/vgf/area-statements", {
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getVGFGroupStatements = (cancelToken, groupId) => {
    return axios
      .get(`/api/vgf/group-statements/${groupId}`, {
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getVGFScholarships = (cancelToken) => {
    return axios
      .get("/api/vgf/scholarships", {
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getVGFAreas = (cancelToken) => {
    return axios
      .get("/api/vgf/areas", {
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getVGFSocialMedia = (cancelToken) => {
    return axios
      .get("/api/vgf/social-media", {
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getVGFNextSteps = (cancelToken) => {
    return axios
      .get("/api/vgf/next-steps", {
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getDepartments = (cancelToken) => {
    return axios
      .get(
        "https://www.datos.gov.co/resource/gdxc-w37w.json?$SELECT=dpto&$GROUP=dpto&$ORDER=dpto",
        {
          cancelToken,
        }
      )
      .then(({ data }) => data.map(({ dpto }) => dpto));
  };

  getCitiesByDepartment = (cancelToken, department) => {
    return axios
      .get(
        `https://www.datos.gov.co/resource/gdxc-w37w.json?$SELECT=nom_mpio&$where=dpto='${department}'&$ORDER=nom_mpio`,
        {
          cancelToken,
        }
      )
      .then(({ data }) => data.map(({ nom_mpio }) => nom_mpio));
  };

  submitVGF = ({
    id,
    name,
    lastName,
    email,
    phone,
    birthdate,
    parentName,
    parentPhone,
    testCode,
    areaChoices,
    groupScores,
    preferredAreaId,
    preferredSocialM,
    nextStep,
  }) => {
    return axios
      .post("/api/vgf", {
        id,
        name,
        last_name: lastName,
        email,
        phone,
        birthdate,
        parent_name: parentName,
        parent_phone: parentPhone,
        test_code: testCode,
        preferred_area_id: preferredAreaId,
        social_media_id: preferredSocialM,
        next_step_id: nextStep,
        area_choices: areaChoices,
        group_scores: groupScores,
      })
      .then(({ data }) => {
        if (data.ok) this.notifyFinishedVGF(testCode);
        return data;
      });
  };

  getVGFReportResults = (type, id, year) => {
    return axios
      .get(`/api/admin/vgf/report/${type}/${id}/${year}`, {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  getVGFStudentReportResults = (id) => {
    return axios
      .get(`/api/admin/vgf/student-report/${id}`, {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  GetVGFGlobalResults = (city, scholarship, year) => {
    return axios
      .get(
        `/api/admin/vgf/globalreport?city=${city}&scholarship=${scholarship}&year=${year}`,
        {
          headers: { Authorization: this.token },
        }
      )
      .then(({ data }) => data);
  };

  sendResultsByEmail = (email, base64data, area, programs) => {
    return axios.post("/api/vgf/email-results", {
      email,
      base64data,
      area,
      programs,
    });
  };

  getVGFCities = () => {
    return axios
      .get("/api/admin/vgf/cities", {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  getAllVGFInstitutions = (cancelToken) => {
    return axios
      .get(`/api/admin/vgf/institutions`, {
        headers: { Authorization: this.token },
        cancelToken,
      })
      .then(({ data }) => data);
  };

  getVGFInstitutions = (
    cancelToken,
    rowsPerPage = 15,
    page = 1,
    search = ""
  ) => {
    return axios
      .get(
        `/api/admin/vgf/institutions?rowsPerPage=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: { Authorization: this.token },
          cancelToken,
        }
      )
      .then(({ data }) => data);
  };

  AddVGFInstitution = ({
    name,
    responsible,
    phone,
    email,
    department,
    city,
    term,
  }) => {
    return axios.post(
      "/api/admin/vgf/institutions",
      {
        name,
        responsible,
        phone,
        email,
        department,
        city,
        term,
      },
      {
        headers: { Authorization: this.token },
      }
    );
  };

  updateVGFInstitution = ({
    id,
    name,
    responsible,
    phone,
    email,
    department,
    city,
    term,
  }) => {
    return axios
      .put(
        "/api/admin/vgf/institutions/" + id,
        {
          name,
          responsible,
          phone,
          email,
          department,
          city,
          term,
        },
        {
          headers: { Authorization: this.token },
        }
      )
      .then(({ data }) => data);
  };

  removeVGFInstitution = (id) => {
    return axios
      .delete("/api/admin/vgf/institutions/" + id, {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  getVGFTestsByInstitutionId = (
    cancelToken,
    institutionIds,
    rowsPerPage = 15,
    page = 1,
    search
  ) => {
    return axios
      .get(
        `/api/admin/vgf/test?rowsPerPage=${rowsPerPage}&page=${page}&search=${search}`,
        {
          params: {
            institution_ids: institutionIds,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
          headers: { Authorization: this.token },
          cancelToken,
        }
      )
      .then(({ data }) => data);
  };

  AddVGFTest = ({
    institution,
    scholarship,
    total_students,
    tickets,
    date,
  }) => {
    return axios.post(
      "/api/admin/vgf/test",
      {
        vgf_institution_id: institution,
        scholarship_id: scholarship,
        total_students: total_students,
        tickets,
        date,
      },
      {
        headers: { Authorization: this.token },
      }
    );
  };

  updateVGFTest = ({ id, institutionId, scholarshipId, totalStudents }) => {
    return axios
      .put(
        "/api/admin/vgf/test/" + id,
        {
          vgf_institution_id: institutionId,
          scholarship_id: scholarshipId,
          total_students: totalStudents,
        },
        {
          headers: { Authorization: this.token },
        }
      )
      .then(({ data }) => data);
  };

  removeVGFTest = (id) => {
    return axios
      .delete("/api/admin/vgf/test/" + id, {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  getVGFStudentsByTestId = (
    cancelToken,
    institutionIds,
    testIds,
    rowsPerPage = 15,
    page = 1,
    search
  ) => {
    return axios
      .get(
        `/api/admin/vgf/test/students?rowsPerPage=${rowsPerPage}&page=${page}${
          search ? "&search=" + search : ""
        }`,
        {
          params: {
            institution_ids: institutionIds,
            test_ids: testIds,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
          headers: { Authorization: this.token },
          cancelToken,
        }
      )
      .then(({ data }) => data);
  };

  updateTickets = (increments, vgf_test_id) => {
    this.socketInstance().emit(
      "updateTickets",
      {
        increments,
        vgf_test_id,
      },
      (err) => console.log(err)
    );
  };

  sendInstitutionCodeByEmail = (vgf_test_id) => {
    return axios
      .post("/api/admin/vgf/institution-code-email/" + vgf_test_id, null, {
        headers: { Authorization: this.token },
      })
      .then(({ data }) => data);
  };

  listenUpdateTickets = {
    on: (callback) => this.socketInstance().on("updateTickets", callback),
    off: (callback) => this.socketInstance().off("updateTickets", callback),
  };

  redeemCode = (code, callback) => {
    this.socketInstance().emit("redeemCode", code, callback);
  };

  notifyFinishedVGF = (code) => {
    this.socketInstance().emit("vgfFinished", code, (err) => console.log(err));
  };
}

export const client = new Client();
